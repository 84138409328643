import React, { useState } from "react"
import { navigate } from "gatsby"

import createPersistedState from "use-persisted-state"
const trikora_cart = createPersistedState("trikora_cart")
const trikora_authenticate = createPersistedState("isLogedIn")
export const myContext = React.createContext()

const Provider = props => {
  const [isDark, setTheme] = useState(false)
  const [User, setUser] = trikora_authenticate({
    isLogedIn: false,
    token: null,
    user: null,
  })
  const [lieferanten, setLieferanten] = useState(null) //hold lieferanten

  const [isPlaceHolder, setPlaceHolder] = useState(false)
  const [Cart, setCart] = trikora_cart({
    ProductsList: [],
  })

  const addToCart = e => {
    e.preventDefault()
    e.stopPropagation()
    const single = e.currentTarget.getAttribute("data-single")

    const listing = Cart.ProductsList
    if (
      !listing.filter(function (elem) {
        return elem.id === e.currentTarget.id
      }).length
    ) {
      if (e.currentTarget.id !== "") {
        listing.push({
          id: e.currentTarget.id,
          title: e.currentTarget.title,
          slug: e.currentTarget.dataset.slug,
          img: e.currentTarget.dataset.img,
          url: e.currentTarget.dataset.url,
        })
      }
    }

    setCart({ ...Cart, listing })
    // console.log(Cart.ProductsList.length)
    if (single) {
      // console.log("single")
      navigate("/trikora-cart/")
    }
  }
  const removeItem = e => {
    e.preventDefault()
    e.stopPropagation()
    const listing = Cart.ProductsList
    const newCart = listing.filter(x => x.id !== e.currentTarget.id)
    // console.log(newCart)
    setPlaceHolder(!isPlaceHolder)

    setCart({ ...Cart, ProductsList: newCart })
  }
  const changeTheme = () => {
    setTheme(!isDark)
  }
  const login = (isLogedIn, token, user) => {
    setUser({
      isLogedIn: true,
      token: token,
      user: user,
    })
  }
  const logOut = () => {
    setUser({
      isLogedIn: false,
      token: null,
      user: null,
    })
    setLieferanten(null)
  }
  return (
    <myContext.Provider
      value={{
        isDark,
        User,
        login,
        logOut,
        setLieferanten,
        lieferanten,
        changeTheme,
        Cart,
        addToCart,
        removeItem,
        isPlaceHolder,
      }}
    >
      {props.children}
    </myContext.Provider>
  )
}
export default ({ element }) => <Provider>{element}</Provider>
